/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Image, Subtitle, Text, Divider } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Nové knihy"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="--center pb--60 pt--60" style={{"backgroundColor":"var(--color-blend--95)"}} name={"uvod"}>
          
          <ColumnWrap className="column__flex --left el--1 flex--center" style={{"maxWidth":1200}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s3 pb--0 pl--0 pr--0 pt--0" style={{"maxWidth":900}} anim={"2"} animS={"3"}>
              
              <Title className="title-box fs--62 w--900" style={{"maxWidth":700}} content={"Nové knihy"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--10 pt--10" name={"ntcvk73w0u"} layout={"l3"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":193}} src={"https://cdn.swbpg.com/t/25013/3b760f5f24a14e708bb85d7d8c78442d_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/25013/3b760f5f24a14e708bb85d7d8c78442d_s=350x_.jpg 350w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"Lukášové trampoty"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"Zuzana Dodoková"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"Dvanásťročný Lukáš prechádza náročným obdobím dospievania. Mladšia sestra aj rodičia mu lezú na nervy, najradšej trávi čas vo svojej izbe uprostred vlastného poriadku, čo v maminom preklade znamená hotový bordel. V škole síce nerozumie učiteľom a spolužiaci občas tiež zazerajú, no jedno sa musí nechať: Lukáš má zmysel pre pointu a chrlí trefné poznámky, ktoré fungujú na všetkých. Odkiaľ ich berie? Zbožňuje čítať staré grécke báje a povesti. A po najväčšom hrdinovi antického sveta dokonca získal prezývku.\n<br>Koľko odvahy, rozumu a citu sa v Lukášovi skrýva? Postaví sa Ferovi z céčky, ktorý si na všetkých dovoľuje? Dokáže údery vracať sám, alebo bude potrebovať pomoc? Ako sa ubráni šikane?\n<br>Autorka hravou formou dokresľuje dej aj pomocou mytologických pojmov. Vďaka nim sa dozvieme, čo znamená otvoriť Pandorinu skrinku, zažívať Tantalove muky, vyčistiť Augiášov chliev a najmä, koľko úloh musí splniť Herakles, aby bol konečne slobodný. <a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=90943&amp;showAnot=1&amp;pageId=resultform\" target=\"_blank\">&gt;&gt;&gt;<br></a>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":193}} src={"https://cdn.swbpg.com/t/25013/86981deda0d64a0e84a7238fc5ddb01b_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/25013/86981deda0d64a0e84a7238fc5ddb01b_s=350x_.jpg 350w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"Pozoruhodná místa Čech, Moravy a Slezska"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"Magdalena Wagnerová"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"Kedysi dávno praotec Čech vystúpil na horu Říp a pozrel sa na našu budúcu vlasť. Benediktínsky kláštor v mestečku Sázava sa stal centrom slovanskej liturgie a ako taký sa nezmazateľne zapísal do našich dejín. Kedysi dávno sa v južných Čechách usadil šľachtický rod, ktorý sa stal najmocnejším z mocných a ktorého predstavitelia sídlili na hradoch Rožmberk a Český Krumlov. V srdci Českého raja dodnes vystupujú zo zeme dve čadičové veže známe ako Panna a Baba, pozostatky slávnej minulosti rodu Vartemberkovcov...\n<br>Tieto a mnohé ďalšie pozoruhodné miesta, ktoré nájdete v tejto knihe, by nemali uniknúť našej pozornosti, či už sa na ne vyberieme s rodinou, na výlet, za poznaním alebo za zábavou.<a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=90865&amp;showAnot=1&amp;pageId=resultform\" target=\"_blank\">&gt;&gt;&gt;<br></a>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":193}} src={"https://cdn.swbpg.com/t/25013/fac7f3ab728e43e39700581633cc8ade_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/25013/fac7f3ab728e43e39700581633cc8ade_s=350x_.jpg 350w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"Kde si sa vydala, tam si mlieko pýtaj. Kniha o živote, smrti a odpusteníSlovenska"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"Júlia Marcinová"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"Román napísaný na základe skutočných udalostí sa odohráva v rokoch 1860 až 1944 v Poluvsí, ktoré je dnes časťou Rajeckých Teplíc. Rozpráva príbeh rodiny naprieč generáciami tak, ako sa skutočne stal: bez prikrášlenia, bez zjemnenia, s drsnosťou dedinského prostredia, ale aj s vľúdnosťou obyčajného človeka. Život bol voči hlavným protagonistom neraz neúprosný, no napriek tomu nás silným príbehom sprevádza odpustenie, odvaha a pokora.\n<br>\nAutorka Júlia Marcinová sa profesionálne venuje etnológii a tradičnému odievaniu. Táto výnimočná kniha s výnimočným príbehom mapuje históriu jej rodiny. V románe vystupujú osoby, ktoré svoj život v obci Poluvsie – Rajecké Teplice naozaj prežili a viacerí ho žijú doteraz. <a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=91035&amp;showAnot=1&amp;pageId=resultform\" target=\"_blank\">&gt;&gt;&gt;<br></a>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":193}} src={"https://cdn.swbpg.com/o/25013/66e22b5bac4c4285adb459b058acad98.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={""}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"Prognóza je dobrá. 27 pútavých príbehov z dejín Slovenska"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"Milan Krajniak"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"Ponorte sa do 27 pútavých príbehov z dejín Slovenska. Spoznajte osobnosti a udalosti našej histórie, o ktorých ste možno doteraz počuli iba veľmi málo a zistíte, že sme oveľa zdravší, silnejší a životaschopnejší, než o nás hovoria.\n\n<br><br>Vedeli ste, že\n<br>- Marcus Aurelius písal svoje najznámejšie dielo na území Slovenska?\n<br>- Svätopluk neprehral ani jednu vojnu?\n<br>- slovenský arcibiskup zachránil Viedeň pred Turkami?\n<br>- slovenský maršal dobyl Berlín?\n<br>- Bratislava bola hlavným mestom Uhorska dlhšie ako Budapešť?\n<br>- SNP bolo druhé najväčšie povstanie v tyle nemeckého Wehrmachtu?\n\n<br><br>Kniha, ktorú autor napísal pre svoju dvadsaťročnú dcéru, môže zaujať všetkých, ktorí hľadajú darček pre mladého čitateľa.\n\nPopri pútavo napísaných príbehoch autor na záver knihy sumarizuje dejinné skúsenosti do niekoľkých princípov, ktoré pomáhajú čitateľovi pochopiť slovenskú prítomnosť a rozmýšľať o budúcnosti Slovenska. <a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=90987&amp;showAnot=1&amp;pageId=resultform\" target=\"_blank\">&gt;&gt;&gt;<br></a>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":193}} src={"https://cdn.swbpg.com/t/25013/feb1aaf2a1c74fbdbba044d39549428d_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/25013/feb1aaf2a1c74fbdbba044d39549428d_s=350x_.jpg 350w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"Nevzdám to! Aurel Stodola"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"Jozef Banáš"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"Po úspešných motivačných biografiách o Milanovi Rastislavovi Štefánikovi a Tomášovi Baťovi prináša Jozef Banáš tretí energizujúci román o jednom z najvýznamnejších svetových vedcov prvej polovice 20. storočia, Slovákovi a švajčiarskom občanovi Aurelovi Stodolovi. Ten sa nielenže stal vysnívaným profesorom na jednej z najvyspelejších technologických vysokých škôl na svete – ETH Zürich –, ale stal sa jej najmladším profesorom v dovtedajšej histórii. Stodolu postihli tragické požiare, národnostný útlak, rodinné tragédie, prežil dve vojny a polovicu života strávil v cudzine. Boj s nepriazňou a najmä s vlastnými slabosťami bol jeho každodenným chlebom. Nevzdával sa za žiadnych okolností, hoci sa zdalo, že riešenie nie je, kráčal vpred, aj keď už zdanlivo nebolo kam. Prísna disciplína a tvorivosť boli základným ukotvením jeho života. V súkromí žil v tichom zúfalstve, navonok však nedal nič znať, naopak, rozdával energiu, pomáhal a povzbudzoval milovaných študentov, ktorí milovali jeho. <a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=91004&amp;showAnot=1&amp;pageId=resultform\" target=\"_blank\">&gt;&gt;&gt;<br></a>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":193}} src={"https://cdn.swbpg.com/t/25013/5852d6af6cd247bc8b3b24889cec1a92_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/25013/5852d6af6cd247bc8b3b24889cec1a92_s=350x_.jpg 350w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"Soľ nad zlato : z najkrajších slovenských rozprávok"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"Pavol Dobšinský"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"Pred takmer polstoročím vyšiel tento výber najkrajších. najznámejších rozprávok zo zbierok Pavla Dobšinského, s malebnými obrázkami Emila Makovického. Nadčasovo krásne príbehy v novej úprave. ale s pôvodnými ilustráciami majstra. vtiahnu deti aj ich rodičov do fascinujúceho sveta ježibáb. drakov. zlých mocností. ale aj krásnych princezien a udatných junákov bojujúcich so zlom a víťaziacich nad ním. <a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=90972&amp;showAnot=1&amp;pageId=resultform\" target=\"_blank\">&gt;&gt;&gt;<br></a>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":193}} src={"https://cdn.swbpg.com/t/25013/b72b27b2d07542e59d8dfc01cf8f1fed_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/25013/b72b27b2d07542e59d8dfc01cf8f1fed_s=350x_.jpg 350w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"Ohlodaná kost : vzdělanost jako cesta k vědomé společnosti"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"Václav Trojan"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"Spoločnosť v poslednom období atakujú mnohé ideológie o tom, že škola už nie je potrebná, že informácie sú všade dostupné a pozícia učiteľa sa otriasa v základoch. Nahradí ho umelá inteligencia? Je naozaj ešte škola potrebná? Autor obhajuje vzdelávanie v školách a postavenie učiteľa bez akýchkoľvek príkras. Bez ružových okuliarov poukazuje na tradičné stereotypy, ktoré môžu pôsobiť navonok skostnatene, ale v praxi sa stále ukazujú ako efektívne. V dnešnej dobe sa mnohí stavajú do pozície odborníka na školstvo a deklarujú najúčinnejšie reformy vzdelávania, ktoré posunú našu spoločnosť vpred. Každý hovorí do vzdelávania – rodičia, žiaci, zriaďovatelia, politici,... a možno práve preto nám už zo školstva ostala len ohlodaná kosť...&nbsp;<a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=90882&amp;showAnot=1&amp;pageId=resultform\" target=\"_blank\">&gt;&gt;&gt;<br></a>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":193}} src={"https://cdn.swbpg.com/t/25013/2170e40cfb294c338e0fa89cc419cee5_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/25013/2170e40cfb294c338e0fa89cc419cee5_s=350x_.jpg 350w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"Prorokyňa a trkvas"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"Jonas Jonasson <br>"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"Veselý príbeh o tom, ako si užiť cestu okolo sveta, kým nastane armagedon. Do zániku sveta nezostáva veľa času. Aspoň podľa amatérskej astrofyzičky Petry, ktorá súdny deň vypočítala na 7. septembra 2011 o 20:21. <br>Toto proroctvo ju nečakane spojí s pomerne jednoduchým Johanom, ktorý odmalička slepo obdivoval staršieho brata, a s dôchodkyňou Agnes, ktorá sa vydáva za mladú influencerku. Nesúrodá trojica sa v karavane vydáva do Ríma a majú poriadne naponáhlo. Musia toho ešte veľa stihnúť, ale ako to už býva, nič nejde podľa plánu. Dokonca ani koniec sveta.\n<br>Na pozadí bizarného výletu naprieč Európou, Afrikou a Amerikou Jonasson humorne a svojsky odkrýva nielen výnimočné ľudské charaktery, ale odhaľuje aj to, ako funguje korupcia na globálnej úrovni.&nbsp;<a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=90766&amp;showAnot=1&amp;pageId=resultform\" target=\"_blank\">&gt;&gt;&gt;<br></a>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":193}} src={"https://cdn.swbpg.com/t/25013/75328c72e6ee4406b4d58914e40c1268_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/25013/75328c72e6ee4406b4d58914e40c1268_s=350x_.jpg 350w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"Tajný život kníh "}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"Tom Mole&nbsp;"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"\nTáto kniha je pre milovníkov kníh, knihomoľov a pre tých, ktorí knihu považujú za niečo výnimočné. Vedeli ste, prečo sa zaviedlo prisahanie na knihy? A že dnes americkí kongresmani nemusia prisahať na Bibliu, ale môžu si zvoliť ľubovoľné dielo? Vedeli ste, aké rozličné typy čitateľov existujú? Určite poznáte znalca a milovníka kníh, ktorý má byt zaprataný knihami. No aj takého, pre koho sú knihy skôr ozdobou bytu, kupuje si ich podľa toho, či pôsobia reprezentatívne, a zoraďuje si ich podľa farieb.\n<br>Táto kniha je aj o tom, ako nás, jednotlivcov, knihy menia spôsobom, ktorý nemá nič spoločné s obsahom knihy, s jej posolstvom. Je o tom, ako sa knihy a čitatelia v priebehu času vyvíjali. A je aj o tom, prečo majú knihy stále, dokonca aj s príchodom iných médií, moc meniť naše životy.\n<br>Tom Mole píše tak živo, až máte pocit, akoby sedel pri vás a zaujato vám o knihách rozprával. Venuje sa knihám ako fyzickým predmetom, ktoré figurujú v ľudskej kultúre. Nerozoberá, o čom sa v knihách píše, zameriava sa na to, čo knihy znamenajú.\n<br>Lahôdka pre všetkých bibliofilov.&nbsp;<a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=90805&amp;showAnot=1&amp;pageId=resultform\">&gt;&gt;&gt;</a><br>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":193}} src={"https://cdn.swbpg.com/t/25013/f8b93f69866b4ac58c93d68879c656f3_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/25013/f8b93f69866b4ac58c93d68879c656f3_s=350x_.jpg 350w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"Dračia šupina "}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"Henrich H. Hujbert&nbsp;"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"\nZabudnuté povesti z čias našich predkov, kedy ešte Všehomíru vládli veľkí bohovia, po oblohe krúžili draci a v lesoch šantili víly. Tieto príbehy odkrývajú neľahký osud nielen ľudí, ale aj čarovných bytostí. Večný boj za lásku a mier odhaľuje pravé povahy hlavných hrdinov a na priadzi utkanej Rožanicami ich posúva v ústrety neľahkému osudu.\nKruté súboje a podmanivé čary sa striedajú na každej strane. Temní besi, trpaslíci a bludičky striehnu na krehké duše. Morana chladným dychom ovanie krajinu, Perún udrie hromom a Svarog zahreje slnečným lúčom. Ozveny zabudnutých čias znova zahučia roklinami, lesmi i našimi srdciami. Stačí trocha odvahy, zdvihnúť nohu a spraviť prvý krok. Svety našich predkov vítajú čitateľa...\n<a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=90234&amp;showAnot=1&amp;pageId=resultform\">&gt;&gt;&gt;</a><br>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":193}} src={"https://cdn.swbpg.com/t/25013/fec9382aaa14472b953b049beeef0b89_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/25013/fec9382aaa14472b953b049beeef0b89_s=350x_.jpg 350w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"13 matematických príbehov "}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"Milan Hejný&nbsp;"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"\nMáte radi matematiku a zároveň radi čítate?\nPotom kniha 13 matematických príbehov je kniha práve pre vás!\nPonorte sa do tajuplných matematických príbehov, vyriešte úlohy a hlavolamy a získajte správne heslo na pripojenie do siete.\nÚlohy spracované do napínavých príbehov sú určené pre žiakov a žiačky 3. – 9. ročníka ZŠ a pre každého, kto má rád čísla či hlavolamy, bez rozdielu veku. Sú to malé literárne diela (rozprávky, sci-fi, poviedky), ktoré obsahujú niekoľko matematických úloh. Prekážkami pre hrdinov príbehu sú práve úlohy, ktoré sú súčasťou deja. Čitatelia sú motivovaní tieto úlohy vyriešiť.\nMatematické príbehy nájdu uplatnenie v každej fáze vyučovacieho procesu. Môžu slúžiť na motiváciu, precvičovanie a upevňovanie učiva, prípadne aj na preverovanie vedomostí či čítanie s porozumením.\nPublikácia je pokračovaním legendárnej knihy 16 matematických príbehov, ktorá vyšla v roku 1983.\n&nbsp;<a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=90818&amp;showAnot=1&amp;pageId=resultform\">&gt;&gt;&gt;</a><br>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":193}} src={"https://cdn.swbpg.com/t/25013/99ad6d7b5c3943fe844d613da1b25616_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/25013/99ad6d7b5c3943fe844d613da1b25616_s=350x_.jpg 350w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"Dávid a Goliáš "}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"Václav Neuer&nbsp;"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"\nKto potreboval zabiť invalida na vozíčku? To je jedna z prvých otázok, ktorá preletí hlavou komisárovi Ledeckému po tom, čo ho privolajú k novému prípadu.\n\nZnamená odrezaný prst odkaz? Ak áno, pre koho? Patrí policajtom alebo okoliu zavraždeného?\n\nIndície ukazujú na dávno nečinnú mafiánsku skupinu. Mnoho jej bývalých členov je už mŕtvych alebo mimo diania, no niekto sa aj tak snaží ich rady preriediť. \n\nVšetko nasvedčuje, že vrahov je viac a sú to profesionáli. Lenže kto ich najal a prečo? Nik zatiaľ netuší, že všetko sa začalo pred mnohými rokmi v jednej z bratislavských ulíc...\n\nNová kniha Václava Neuera prináša čitateľom ďalší príbeh vrchného inšpektora Ledeckého a jeho kolegov. Autor voľne nadväzuje na svoje predchádzajúce tituly (Krkavčí súd, Vražda s pridanou hodnotou, Nenapravený omyl, Tiene minulosti, Prekliate dedičstvo, Uplakaná jeseň, Priveľa podozrivých a Medzi nebom a zemou), ktorých hlavnými hrdinami sú členovia bratislavskej mordparty. Vychádza zo svojich skúseností elitného policajta a z detailnej znalosti práce polície a vyšetrovacích postupov. \n&nbsp;<a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=73044&amp;showAnot=1&amp;pageId=resultform\">&gt;&gt;&gt;</a><br>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":193}} src={"https://cdn.swbpg.com/t/25013/88eb94c8a0c24dec827dc9ee96486502_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/25013/88eb94c8a0c24dec827dc9ee96486502_s=350x_.jpg 350w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"Liptov. Kuchyňa starých materí "}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"Iveta Zuskinová&nbsp;"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"\nSúčasná etnológia a kultúrna antropológia považuje tradičnú kuchyňu spoločenstiev ľudí za jeden z ich najvýznamnejších identifikačných znakov a zasadzuje sa za jej zdokumentovanie a zachovanie pre budúce generácie. S potešením som si preto prečítala túto knihu venovanú Liptovu, ktorá spĺňa tieto kritéria a dopĺňa pestrú mozaiku kulinárskych tradícii Slovenska. Autorka nás v texte sprevádza celou genézou podmienok, ktoré vplývali na charakter stravy ľudí na Liptove. Zaujímavým prvkom knihy sú viaceré historické dokumenty súvisiace so stravovaním na Liptove. Osviežujúce sú pasáže osobnej reflexie autorky, ktorá ukazuje, že záujem o tradičnú kuchyňu Liptova si pestovala už od detstva v rámci svojej rodiny a rozšírila ju ako etnografka aj do profesionálneho bádania. V rámci tejto časti knihy prezentuje aj ňou vyskúšané recepty regionálnych jedál. Prof. PhDr. Rastislava Stoličná, DrSc. \n&nbsp;<a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=90271&amp;showAnot=1&amp;pageId=resultform\">&gt;&gt;&gt;</a><br>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":193}} src={"https://cdn.swbpg.com/t/25013/4a2d417486c7467082d841cb2af9ee2b_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/25013/4a2d417486c7467082d841cb2af9ee2b_s=350x_.jpg 350w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"Eugen Onegin"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"Alexander Sergejevič Puškin&nbsp;"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"Eugen Onegin patrí medzi vrcholné diela A. S. Puškina a klenoty svetovej literatúry. Román opisuje život ruskej spoločnosti v prvej polovici 19. storočia. Onegin je prototypom tzv. zbytočného človeka, nemá nijaké životné ciele a všetky jeho snahy sú odsúdené na zánik, aj keď kedysi bol vzdelaným a nadaným človekom. Odcestuje z Petrohradu na vidiek za umierajúcim strýkom, a hoci o tom zatiaľ netuší, práve tam stretne svoju osudovú lásku Tatianu. Nezameniteľný preklad Jána Štrassera dopĺňajú úchvatné ilustrácie mladej talentovanej ilustrátorky Lenky Šimečkovej.  Autor použil v tomto diele tzv. „oneginovskú“ strofu, ktorá sa skladá zo 14 veršov. Dielo je dokonalou ukážkou štylistických a lexikálnych možností ruského jazyka.&nbsp;<a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=90210&amp;showAnot=1&amp;pageId=resultform\" target=\"_blank\">&gt;&gt;&gt;</a><br>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":193}} src={"https://cdn.swbpg.com/o/25013/3aead695fe7b4c37bb8b9ed6b1b6690b.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={""}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"Stromkáči"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"Roman Brat&nbsp;"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"Všetci za jedného, jeden za všetkých!\n<br>Zvieratká, ktoré spolu obývajú jeden strom, sa dozvedia zlú novinu. Do ich lesa vtrhli s pílami pajkovia, ako nazývajú ľudí, a ničia ho. Vypukne panika. Starý jazvec zvolá poradu a spýta sa susedov: „Vzdáme sa a odídeme inam, alebo budeme vzdorovať?“ „Budeme vzdorovať,“ zaznie zborovo. Kuna, ďateľ, sova a ďalší sú odhodlaní svoj domov zachrániť. Zabudnú, že si občas lezú na nervy, a zomknú sa v jeden tím. Od tej chvíle sú stromkáči! Začnú sa svorne schádzať u jazveca a aby neriešili iba pajkov, dohodnú sa, že si každý večer vyrozprávajú jeden príbeh na rozveselenie. Trochu napínavý... trochu žartovný. Týmito príbehmi si nielen krátia čas pri strážení lesa, ale zároveň sa povzbudzujú v súboji so silným protivníkom. Zbaviť sa pajkov totiž vôbec nebude ľahké, a tak im pri tom držme prsty.&nbsp;<a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=90715&amp;showAnot=1&amp;pageId=resultform\" target=\"_blank\">&gt;&gt;&gt;</a><br>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":193}} src={"https://cdn.swbpg.com/o/25013/2916f1000c114f5c91ba7efa0f82bbb2.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={""}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"Cesty hrdinov"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"Ivana Molnárová Dubcová&nbsp;"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"Humorná fantasy o sile priateľstva, viery a odhodlania a o presvedčení, že osud je vlastne iba výsledkom prílišnej kreativity bohov!\nO čom môže byť príbeh, ktorého hlavný hrdina umrel skôr, ako sa vôbec začal? Baltar sa vďaka ľsti vracia z podsvetia, odhodlaný vziať svoj život do vlastných rúk. Vlastne iba jednej, pretože tá druhá mu ostala nehmotná. Čo je to za bojovníka, ktorý nemôže používať jednu ruku? Aby sa Baltar opäť stal celým človekom, musí sa vydať v ústrety výzvam, aké si dovtedy nedokázal ani predstaviť, ale predovšetkým musí niesť následky za svoje činy. Pretože oklamať démona asi nebol najlepší nápad. Po tŕnistej ceste našťastie odvážny bojovník nekráča sám. Zvládne sa tvárou v tvár fantastickým tvorom, hordám nepriateľských armád či miske s kláštornou obilnou kašou zachovať vždy správne a zlomiť tak démonovu kliatbu?&nbsp;<a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=90248&amp;showAnot=1&amp;pageId=resultform\" target=\"_blank\">&gt;&gt;&gt;</a><br>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":193}} src={"https://cdn.swbpg.com/t/25013/3d24657a7ee24feb8b7f45ed2c0c097a_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/25013/3d24657a7ee24feb8b7f45ed2c0c097a_s=350x_.jpg 350w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"Babylon"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"Gaston Dorren&nbsp;&nbsp;"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"Ako sa môžete čo najefektívnejšie naučiť po vietnamsky? Prečo je nemčina najväčším čudákom spomedzi všetkých jazykov? A ako spolu komunikujú Indonézania, ktorých je 265 miliónov, žijú na takmer tisícke ostrovov a hovoria sedemsto jazykmi? Pripravte sa, čaká vás Babylon – divoká jazyková cesta okolo sveta!\n\nDvadsať najrozšírenejších jazykov sveta vám vraj umožní dohovoriť sa s troma štvrtinami ľudskej populácie. Polyglot Gaston Dorren nám vo svojej zábavnej a poučnej knihe Babylon predstavuje špecifiká každého z tejto svetovej dvadsiatky a veľmi pútavo približuje všetko, čo s nimi súvisí – gramatické zásady, abecedu, zvukovú podobu, či zaujímavé slová, ktoré z nich prenikli do celého sveta. Pozrieme sa však taktiež na históriu, kultúru i rôzne spôsoby myslenia a vnímania reality spojené s každým z dvadsiatky najrozšírenejších jazykov sveta.&nbsp;<a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=90284&amp;showAnot=1&amp;pageId=resultform\" target=\"_blank\">&gt;&gt;&gt;</a><br>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":193}} src={"https://cdn.swbpg.com/t/25013/8a952a7006b84c1f9d7e68314b5c0183_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/25013/8a952a7006b84c1f9d7e68314b5c0183_s=350x_.jpg 350w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"Súboj s láskou"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"Jana Pronská&nbsp;&nbsp;"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"Román s názvom Súboj s láskou je inšpirovaný skutočnými udalosťami z našej histórie. Zavedie nás na tajomný, povesťami opradený hrad Bystrica, na ktorom sa odohráva nezabudnuteľný príbeh o láske, zrade a pomste. Bratia Rafael a Ján Podmanickí z Bystrice, mocní veľmoži a lúpežní rytieri, ovládajú celé Považie a trasie sa pred nimi i šľachta na Morave a v Sliezsku. Sú nielen neľútostní bojovníci, ale aj obratní diplomati a všetky útoky proti nim sa končia ich víťazstvom. V Bystrici však žije Janika, dcéra pána z Lomnice, ktorú vychovali drsní žoldnieri z Rafaelovej družiny. Odjakživa sa zo všetkých síl usilovala získať uznanie bratov Podmanických. Keď sa jej konečne podarí uspieť medzi bojom ošľahanými mužmi, na hrade sa zjaví očarujúca zajatkyňa a Janika si musí vybrať: buď zabojuje o Rafaelovo srdce, alebo sa vzdá a príde o všetko. No ako má presvedčiť muža, o ktorého stojí, že krásna tvár nie je všetko, na čom v živote záleží? Že nie vždy ten, kto o láske hovorí, ju aj skutočne cíti? A stihnú si vôbec dve srdcia nájsť k sebe cestu, keď sa nad nimi začnú sťahovať mračná pomsty a zrady?&nbsp;<a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=73456&amp;showAnot=1&amp;pageId=resultform\" target=\"_blank\">&gt;&gt;&gt;</a><br>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":193}} src={"https://cdn.swbpg.com/o/25013/8406150acbb5491a8af86bfc8c29dfd9.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={""}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"Mačka, ktorá zachránila knihy  "}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"Sósuke Nacukawa\n\n"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"Rintaró Nacuki je len stredoškolák, no na pleciach mu už leží zodpovednosť za tisíce príbehov. Po milovanom starom otcovi totiž nezdedil len lásku ku knihám, ale aj jeho antikvariát. S antikvariátom je však viac starostí ako radostí a tak sa ho rozhodne zavrieť. Vtedy sa pred ním zjaví hovoriaca mačka a požiada ho, aby jej pomohol knihy zachrániť. Mnohé z nich sú opustené a zdá sa, že vyslobodiť ich od nedbanlivých majiteľov dokáže iba táto netradičná dvojica. A tak sa mačací knižný ochranca a Rintaró vydávajú na úžasnú výpravu do sveta fantázie, do spletitých bludísk, kde na nich čaká nejedno dobrodružstvo.   <a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=73455&amp;showAnot=1&amp;pageId=resultform\" target=\"_blank\">&gt;&gt;&gt;</a><br>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":193}} src={"https://cdn.swbpg.com/o/25013/c41b30e469154ef3afc3731321ab9d47.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={""}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"Umenie reči. Medzi sofistikou a sokratikou  "}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"Vladislav Suvák\n\n"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"To, čo sa zdá bežným ľuďom nereálne, je pre Sáru rutina. Je šéfredaktorkou módneho časopisu a ako novinárka sa už stretla azda so všetkými slávnymi osobnosťami – spevákmi, hercami, športovcami u nás i v zahraničí. Mnohých mala možnosť navštíviť v súkromí ich honosných víl a palácov, s inými sa dokonca skamarátila, takže ju už len tak nič nezaskočí. Až kým nestretne talianskeho prezidenta. Príbeh tajnej lásky, ktorý sa začne ako rozprávka, však čoskoro narazí na realitu. Román o tom, že o našom šťastí by nemal rozhodovať nik iný, iba my sami.   <a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=90203&amp;showAnot=1&amp;pageId=resultform\" target=\"_blank\">&gt;&gt;&gt;</a><br>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":193}} src={"https://cdn.swbpg.com/t/25013/6c2685c07db64c81b80d900c47e08cb8_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/25013/6c2685c07db64c81b80d900c47e08cb8_s=350x_.jpg 350w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"Sára kráča do paláca  "}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"Jana Benková\n\n"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"To, čo sa zdá bežným ľuďom nereálne, je pre Sáru rutina. Je šéfredaktorkou módneho časopisu a ako novinárka sa už stretla azda so všetkými slávnymi osobnosťami – spevákmi, hercami, športovcami u nás i v zahraničí. Mnohých mala možnosť navštíviť v súkromí ich honosných víl a palácov, s inými sa dokonca skamarátila, takže ju už len tak nič nezaskočí. Až kým nestretne talianskeho prezidenta. Príbeh tajnej lásky, ktorý sa začne ako rozprávka, však čoskoro narazí na realitu. Román o tom, že o našom šťastí by nemal rozhodovať nik iný, iba my sami.   <a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=73114&amp;showAnot=1&amp;pageId=resultform\" target=\"_blank\">&gt;&gt;&gt;</a><br>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":193}} src={"https://cdn.swbpg.com/t/25013/8d0543b913864b9ab438d446eca2137e_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/25013/8d0543b913864b9ab438d446eca2137e_s=350x_.jpg 350w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"Návod na obsluhu rodičov  "}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"Emília Popálencová\n\n"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"Humorné zážitky a rady dievčatka Laury, ako vychádzať nielen s rodičmi. Vtipné a milé svedectvo Laury, ktorá čitateľom predstaví rôzne každodenné situácie v ich rodinnom živote v dvadsiatich dvoch kapitolách, a zároveň ponúkne rady, ako s rodičmi (a so súrodencami) vychádzať. Zažili ste už nákupnú horúčku, náhlu túžbu stať sa kaderníčkou (aby ste to mohli okamžite oľutovať), spoločnú dovolenku či prvý školský deň? Napriek ustavičnému hundraniu na mamine či otcove príkazy a zákazy si však Laura dostatočne uvedomuje dôležitosť vzťahov v rodine a lásku, ktorá je nielen pre deti kľúčová. Knižka je vhodná aj na prvé samostatné čítanie a o hravé ilustrácie ju obohatila talentovaná Petra Lukovicsová.   <a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=73221&amp;showAnot=1&amp;pageId=resultform\" target=\"_blank\">&gt;&gt;&gt;</a><br>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":193}} src={"https://cdn.swbpg.com/t/25013/bd760ee109dd4c868f0d86938dd95284_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/25013/bd760ee109dd4c868f0d86938dd95284_s=350x_.jpg 350w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"Aby si sa nestratil v našej štvrti  "}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"Patrick Modiano\n\n"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"Život starnúceho spisovateľa Jeana Daragana, sa začína v posledných dňoch Okupácie. Dej románu siaha do začiatku päťdesiatych rokov, do obdobia jeho prvých detských spomienok, kedy sa Francúzsko snažilo vysporiadať s problémami kolaborácie a vojnového profitérstva. Spisovateľ stretne zvláštne ľudské existencie akoby na pomedzí reality a sna, ktoré v ňom vyvolajú spomienky na dávno zabudnuté udalosti vlastného detstva a osoby s nimi spojené, a ktoré dovtedy v jeho pamäti tvorili biele miesta. Pútavo popisuje parížske štvrte Blanche, La Muette, tiché predmestie Saint-Leu, ale aj náhlu cestu na Juh, do miest „za hranicami“ a „do mesta, ktoré sa volá Rím“ – ktoré malý chlapec ešte nikdy nevidel, a kam sa vlastne dostal až neskôr, ako mladý muž. Vtedy vyhľadá niektorých z aktérov jeho detstva, aby na nich vzápätí na ďalších tridsať rokov zabudol... a pripomenie mu ich až nález strateného adresára.   <a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=70293&amp;showAnot=1&amp;pageId=resultform\" target=\"_blank\">&gt;&gt;&gt;</a><br>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":193}} src={"https://cdn.swbpg.com/o/25013/81c0ee73ad044604a67ecead67dc87a6.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={""}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"O budoucnosti. Vyhlídky lidstva  "}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"Martin Rees\n"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"Budúcnosť ľudstva na Zemi závisí od budúcnosti vedy a od toho, ako úspešne budeme využívať technologický pokrok na riešenie našich problémov. Musíme však myslieť racionálne, globálne, kolektívne, optimisticky a predvídať v dlhodobom horizonte. Pokroky v biotechnológiách, kybernetike, robotike a rozvoji umelej inteligencie - ak s nimi budeme zaobchádzať rozumne - nám umožnia pomôcť rozvojovým krajinám a prekonať hrozby, ktorým ľudstvo čelí, od klimatických zmien až po jadrovú vojnu. Kniha O budúcnosti ponúka prístupnou a čitateľnou formou fascinujúci pohľad do oblasti najmodernejšej vedy a techniky pre každého, kto chce pochopiť kľúčové otázky, ktoré určia budúcnosť ľudstva na Zemi a vo vesmíre.   <a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=68281&amp;showAnot=1&amp;pageId=resultform\" target=\"_blank\">&gt;&gt;&gt;</a><br>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":193}} src={"https://cdn.swbpg.com/o/25013/78567d43c5304d2a90da82ea3ce203d0.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={""}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"Zberateľ "}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"Daniel Silva"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"Dvadsiate tretie pokračovanie jedinečnej špionážnej série Daniela Silvu vyvolalo obrovský záujem čitateľov na celom svete. V tomto vzrušujúcom trileri sa legendárny agent a reštaurátor Gabriel Allon podujme vypátrať ukradnuté majstrovské dielo od Vermeera a popritom odhalí sprisahanie, ktoré by mohlo priviesť svet na pokraj nukleárnej katastrofy.  <a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=73614&amp;pageId=resultform&amp;full=0&amp;focusName=bsktchRZ8\" target=\"_blank\">&gt;&gt;&gt;</a><br>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":193}} src={"https://cdn.swbpg.com/t/25013/3bced38f536348a29b39a571a9b23ca0_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/25013/3bced38f536348a29b39a571a9b23ca0_s=350x_.jpg 350w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"Šachuľko Matový a jeho sny "}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"Martin Hunčár"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"Hráte radi šach? Že nie a vôbec nepoznáte jeho pravidlá? V tom prípade je tu pre vás veľmi pekne spracovaná a ilustrovaná kniha pre veľkých i malých s názvom Šachuľko Matový a jeho sny.\nŠachuľko je obľúbený plyšák, ktorého by mali poznať všetci malí šachisti na Slovensku. Rád dovolenkuje v rôznych častiach sveta. Destinácie strieda pravidelne každý mesiac. So svojím Šéfkom a zároveň trénerom Martinom Hunčárom často vedú vtipné dialógy. V knihe nájdeme niekoľko ropzrávkových príbehov, ktoré sú na začiatku obohatené o veselé básničky Lenky Šimkovej. Zoznámime sa aj niekoľkými najlepšími svetovými šachistami súčasnosti. Hravou a jednoduchou formou sa oboznámime s pravidlami šachu pre začiatočníkov a dostaneme aj zopár námetov a užitočných tréningov pre pokročilejších šachistov. V závere kniha obsahuje príbeh s hlbším kresťanským posolstvom.  <a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=72720&amp;showAnot=1&amp;pageId=resultform\">&gt;&gt;&gt;</a><br>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":193}} src={"https://cdn.swbpg.com/o/25013/8bea9fee9a4542d6ad99a1916f8e4785.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={""}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"Posledný ples v Prešporku "}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"Michaela Važanová"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"Historický román sa odohráva v Uhorsku za čias vlády Jozefa II. Pokojné panstvo Pod lesom zasiahne náhla tragédia, keď jeho majiteľ gróf Samuel príde o manželku a novonarodeného syna. Grófov smútok sa snažia rozptýliť švagriná Lujza, sama poznačená osudom a priateľ Jozef Erdődy. Príbehy hrdinov sa odohrávajú v Prešporku, Tepliciach ale aj v Kláštore sv. Kataríny, no i v divokom Sedmohradsku, kde sa schyľuje k dráme. Na pozadí jozefínskych reforiem sa do životov hrdinov vkrádajú intrigy a klamstvá, ale aj láska a priateľstvo.  <a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=71003&amp;showAnot=1&amp;pageId=resultform\">&gt;&gt;&gt;</a><br>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":193}} src={"https://cdn.swbpg.com/o/25013/626762eb756147b4ae6064ccfaa13d82.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={""}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"Novacén: Nadcházející věk hyperinteligence "}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"James Lovelock"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"Termín antropocén sa začal používať pre súčasnosť, keď sa za najvplyvnejší planetárny faktor všeobecne považuje ľudská činnosť. Podľa legendárneho mysliteľa a vedca Jamesa Lovelocka sa však táto éra končí. Ľudstvo totiž vstúpilo do éry novacénu - éry definovanej superpočítačmi a umelou inteligenciou; éry, ktorá bude produkovať oveľa schopnejšie \"bytosti\" ako ľudia. Ale aj tento nový druh bude potrebovať zdravú Zem a jej funkčnú reguláciu teploty. Rovnako ako ľudia.\nDostupné ako e-kniha  <a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=63746&amp;showAnot=1&amp;pageId=resultform\" target=\"_blank\">&gt;&gt;&gt;</a><br>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":193}} src={"https://cdn.swbpg.com/o/25013/4920c316682e484cb140ba727389ab91.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={""}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"O starcovi a jeho knihách : novodobý mýtus"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"Albín B. Urban"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"Príbeh o mladej študentke Bailey, ktorá sa vydá na dobrodružnú a nebezpečnú cestu plnú prekážok a nástrah.\nBailey túži nájsť bájneho starca, o ktorom sa hovorí, že má najväčšiu knižnicu na svete. Nikto však nevie, či naozaj existuje – nielen jeho knižnica, ale aj samotný starec. Ľudia o ňom hovoria ako o postave z mýtov, o hrdinovi starých čias. Sama Bailey pochybuje o jeho existencii, ale čím ďalej pátra a cestuje, čím viac ľudí stretáva, čím viac hádaniek vylúšti, tým viac si je istá, že starca nájde.\nBude však za svoju odvahu, námahu a múdrosť odmenená?\nNájde bájneho muža a jeho poklad v podobe tisícok a tisícok kníh?  <a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=72382&amp;showAnot=1&amp;pageId=resultform\" target=\"_blank\">&gt;&gt;&gt;</a><br>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":193}} src={"https://cdn.swbpg.com/t/25013/dd71ee4982874a99adc6cd2dab0fe3b9_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/25013/dd71ee4982874a99adc6cd2dab0fe3b9_s=350x_.jpg 350w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"Liečivé rozprávky "}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"Monika Juranová"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"Kniha liečivých rozprávok obsahuje netypické rozprávky, v ktorých sú skryté dôležité posolstvá pre rodičov a zároveň sú krásnym počúvaním pre dieťa. Hlavnými hrdinami sú zvieratká, žijúce v rôznych biotopoch, pričom sa deti učia aj novým poznatkom o prírode.\nV rozprávkových príbehoch sa zvieratká ocitajú v rôznych situáciách zo života, ktoré poznáme a máme ich zažité či už v detstve s našimi rodičmi alebo v dospelosti v pozícii rodiča. Rozprávky sú krásnym čítaním pre dieťa, ale zároveň môžu byť terapiou pre rodiča, ktorý bol dieťaťom a nedostal od svojich rodičov to, čo v danej chvíli v detstve skutočne potreboval. Zároveň môžu byť rozprávky inšpiráciou, ako reagovať vo chvíľach, ktoré môžu byť pre dieťa kľúčové.\nPríbehy sú doplnené o pútavé ilustrácie od talentovanej ilustrátorky Viktórie Žitňanovej Nagyovej- Blondook.  <a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=69860&amp;showAnot=1&amp;pageId=resultform\" target=\"_blank\">&gt;&gt;&gt;</a><br>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":193}} src={"https://cdn.swbpg.com/t/25013/e6902b2b47204feeaec30852b23d1c3e_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/25013/e6902b2b47204feeaec30852b23d1c3e_s=350x_.jpg 350w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"Svet starovekého Grécka "}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"Michal Habaj"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"Kniha o starovekom Grécku nesúvisí priamo s opisom významných politických a vojenských udalostí, autor sa sústreďuje na život bežného človeka tej doby, jeho zmýšľanie, radosti a starosti. Publikácia poodhaľuje spôsob myslenia ľudí, vysvetľuje tradície, ktorými sa v živote riadili, poukazuje na život celých obcí i jednotlivcov.\nSpoznávame život v mestách, na poliach, v horách, dostávame sa medzi roľníkov, rybárov, remeselníkov, intelektuálov, športovcov, ale aj medzi prostitútky. Nahliadame do vývoja vojenstva, kultúry i náboženstva, do verejného aj súkromného priestoru rodín. Kniha rozpráva príbeh všedného života od dennej práce cez oslavy, náboženské rituály, sex, násilie až po smrť.  <a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=68603&amp;showAnot=1&amp;pageId=resultform\" target=\"_blank\">&gt;&gt;&gt;</a><br>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":193}} src={"https://cdn.swbpg.com/o/25013/e3d94733b1424d9394c1b44161845778.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={""}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"Pavúk"}>
              </Title>

              <Text className="text-box text-box--left" content={"<span style=\"font-weight: bold;\">Ako lapiť pavúka v jeho vlastnej sieti?</span>"}>
              </Text>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"Lars Kepler"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"Pred troma rokmi dostala Saga Bauerová nečakanú pohľadnicu. Neznámy odosielateľ sa v nej vyhrážal zbraňou s deviatimi bielymi nábojmi, pričom jeden z nich je určený pre inšpektora Joona Linnu. Zachrániť ho vraj môže jedine Saga. Čas však pokojne plynie ďalej a z pôvodnej hrozby sa stane len bezvýznamná provokácia, ktorej Joona Linna neprikladá veľký význam. Až doteraz.\n\nNa malom ostrove pred prístavom Kapellskärs bolo nájdené telo rozpustené v kyseline a na mieste činu ostala biela nábojnica. Odkaz chladnokrvného vraha je jasný. Ďalšia vražda bude nasledovať, ak sa vyšetrovateľom nepodarí rozlúštiť komplikované hádanky.\n\nJoona Linna a Saga Bauerová musia spojiť sily, aby zachránili budúce obete, kým bude príliš neskoro. Vyšetrovanie však neprináša očakávané výsledky a to len prehlbuje ich zúfalstvo. Čo ak sa tento vrah nedá zastaviť? A čo ak sa sami chytili do jeho siete? <a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=70494&amp;showAnot=1&amp;pageId=resultform\" target=\"_blank\">&gt;&gt;&gt;</a><br>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":193}} src={"https://cdn.swbpg.com/o/25013/010a22244c9c426398cd2d9b57fc3373.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={""}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"Adam a tajomstvo orloja"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"Miroslav Dobiaš"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"Napínavý príbeh neposlušného školáka Adama, ktorý zistí, že v čiernom podzemí pod pražským orlojom je ukrytý starý alchymistický vynález: Stroj času!\nNapriek všetkým zákazom ho použije a vydá sa na nebezpečnú cestu do minulosti.\nSkvelá kniha pre deti od desať do deväťdesiat rokov! <a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=70483&amp;showAnot=1&amp;pageId=resultform\">&gt;&gt;&gt;</a><br>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":193}} src={"https://cdn.swbpg.com/t/25013/db6d7e5caa3b4ad181e8befba8632825_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/25013/db6d7e5caa3b4ad181e8befba8632825_s=350x_.jpg 350w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"Krásny svet, kdeže si?  "}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"Sally Rooney"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"Spisovateľka Alice sa zoznámi s Felixom, ktorý pracuje v sklade, a požiada ho, aby ju sprevádzal na služobnej ceste do Ríma. V Dubline sa jej najlepšia priateľka Eileen spamätáva z rozchodu a začína opäť flirtovať so Simonom, s ktorým sa pozná od detstva. Alice, Felix, Eileen a Simon sú stále mladí – no život ich pomaly dobieha. Túžia po sebe a navzájom sa klamú, dávajú sa dokopy a rozchádzajú sa. Majú sex a trápia sa nad ním, strachujú sa o svoje priateľstvo a znepokojuje ich svet, v ktorom žijú. Dokážu uveriť v krásny svet?\n\nKrásny svet, kdeže si? sa venuje rôznym témam – kráse, sláve, sociálnym sieťam, duševnému zdraviu, priateľstvu, láske, prerodu priateľstva na lásku aj tomu, aká je príťažlivosť premenlivá. Autorkine postavy hľadajú lásku a vlastnú identitu aj kultúrnu triedu, do ktorej by mohli patriť alebo sa v nej schovať. Hľadajú nádej vo svete, ktorý sa často zdá beznádejný.&nbsp;<a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=71053&amp;showAnot=1&amp;pageId=resultform\">&gt;&gt;&gt;</a><br>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":193}} src={"https://cdn.swbpg.com/o/25013/40a6bbf95df34d24ae4478e30f079e0c.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={""}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"Život bez liekov "}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"Dionýz Dugas"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"„Musel som žiť do sedemdesiatky, kým som pochopil prírodné zákony zdravého života. Dnes mám osemdesiat a neberiem žiadne lieky. Funguje to! Chcem sa s tým podeliť s každým, kto má aspoň trochu záujem.“ &nbsp;&nbsp;<a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=69539&amp;pageId=resultform\" target=\"_blank\">&gt;&gt;&gt;</a><br>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":193}} src={"https://cdn.swbpg.com/t/25013/cb72845246ca42de9c001ddd434446e8_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/25013/cb72845246ca42de9c001ddd434446e8_s=350x_.jpg 350w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"Kvapky"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"Matúš Mahút"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"Cez Mlynskú dolinu pretečie každý rok 6000 študentov, čo je približná kapacita troch autobusov, ktoré tadiaľ premávajú. Každý z nich si tam prináša svoje skúsenosti, plný kufor a fľašu domácej, aby dokázal prežiť viac ako dva semestre. Jedným z nich je aj Mirko, sympatický obézny Oravčan s chuťou naplniť si hlavu vedomosťami a novými zážitkami. Vysokoškolský život je pestrý a plný prekvapení, čo by dokázali zaplniť stovky kníh. Tu je jedna z nich.&nbsp;&nbsp;<a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=70298&amp;showAnot=1&amp;pageId=resultform\" target=\"_blank\">&gt;&gt;&gt;</a><br>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":193}} src={"https://cdn.swbpg.com/o/25013/693786b0c27647118be75163b631ec40.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={""}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"Stratený v zemi mamutov"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"Pavel Radosta"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"Kniha Pavla Radostu nás zanesie do šírych stepí v ľadovej dobe, na ktorých sa okrem sobov, koní či mamutov potulujú aj naozaj nebezpečné šelmy: levy, jaskynné hyeny či medvede. Dospievajúci Niam dostáva od vodcu kmeňa čoraz viac príležitostí, aby na svojich prvých loveckých výpravách preukázal rýchlosť, obratnosť a vynachádzavosť. Keď celý kmeň ujde pred cudzincami, Niam sa stratí a sám blúdi krajinou, až kým ho na začiatku zimy nenájde jeho staršia sestra. Oboch však v neznámom kraji zajmú pravekí lovci... Na pozadí dobrodružného príbehu sa nám pred očami zjavuje farbistý opis loveckého tábora. Chyže pokryté kožušinami, oheň a vôňa mäsa z ulovených zvierat. Muži vyrábajú zbrane s ostrými čepeľami z pazúrika, ženy pletú koše z prútia, spracovávajú kože alebo splietajú povrazy z lyka. Malé deti sa hrajú so soškami zvierat, tie väčšie si vyrábajú svoje prvé luky. Drobné každodenné starosti z času na čas vystriedajú naozaj veľké výzvy. Uloviť takého mamuta je totiž jedna vec, ale ako ho dostať do tábora? Kniha s pôsobivými ilustráciami Kateřiny Coufalovej dnešným jazykom a s ohľadom na nové poznatky interpretuje obdobie, ktoré spracoval klasik tohto žánru Eduard Štorch.&nbsp;&nbsp;<a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=71004&amp;showAnot=1&amp;pageId=resultform\" target=\"_blank\">&gt;&gt;&gt;</a><br>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":193}} src={"https://cdn.swbpg.com/o/25013/1618982c0872416493bc9e8af50ac16a.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={""}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"Spolok Judášov "}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"Peter Šloser"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"Existovala na Slovensku policajná chobotnica? Vznesené obvinenia a právoplatné odsúdenia bývalých policajných funkcionárov nasvedčujú, že jej chápadlá k nám siahajú. Peter Šloser opäť poukazuje na krivákov z najvyšších poschodí slovenskej polície a zradcov, ktorí sa spreneverili svojmu poslaniu. O život pôjde nielen ministrovi vnútra, horúco bude aj v mafiánskej rodine Šenkovcov. Ako tieto dva prípady súvisia, kto je vrahom a zvíťazí spravodlivosť?\n<br>Podobne ako pri predchádzajúcich Šloserových detektívkach, aj vďaka tejto knihe má čitateľ ojedinelú šancu stať sa účastníkom deja. Autor opäť čerpá inšpiráciu z vlastných skúseností, ktoré využil pri opísaní reálneho policajného zákulisia a vyšetrovania zločinov, za ktoré súdy ukladajú doživotné tresty.&nbsp; &nbsp;<a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=68596&amp;pageId=resultform&amp;full=0&amp;focusName=bsktchRZ96\" target=\"_blank\">&gt;&gt;&gt;</a><br>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":193}} src={"https://cdn.swbpg.com/o/25013/a821a7ccbe9e4e1995cc273085275be1.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={""}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"Rýchlokurz geniality : 42 otázok a odpovedí, ktoré vám pomôžu pochopiť dnešný svet "}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"Ľudovít Ódor"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"Kniha je určená pre každého, kto chce poznať odpovede na otázky typu Prečo? a Ako? a kto vie, že na otázky typu Kto?, Čo? a Kde? tu máme Google. Pre všetkých, čo chcú lepšie chápať život v 21. storočí. Pre tých, ktorí chcú mať aspoň hmlistú predstavu o nosných témach.&nbsp; &nbsp;<a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=70140&amp;showAnot=1&amp;pageId=resultform\" target=\"_blank\">&gt;&gt;&gt;</a><br>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":193}} src={"https://cdn.swbpg.com/o/25013/b48a57cf573940e586ea0288459ceac4.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={""}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"Jedna dobrá vec  "}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"Alexandra Potter"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"Tento román je o priateľstve, hľadaní šťastia a o prežívaní prítomného okamihu. Strhujúco úprimný príbeh plný humoru a postáv, do ktorých sa možno ľahko vcítiť.\n<br>Liv Brooksová po rozvode impulzívne vymení svoj londýnsky mestský život za hornatý Yorkshire. Chce začať odznova. No to nie je vždy jednoduché. Z miestneho útulku sa rozhodne adoptovať psíka Harryho, aby jej nebolo tak smutno. Každý deň sa spolu prechádzajú po okolí, aby Liv lepšie spoznala svoj nový domov, a postupne zisťuje, že nie je jediná, ktorej by prospel nový štart. Spoznáva staršieho osamelého pána Valentína, mladého bojazlivého chlapca Stenleyho a Mayu, tínedžerku, ktorá je nahnevaná na celý svet. A veci sa pomaly začínajú meniť. Jedna dobrá vec je srdcervúci román o priateľstve a hľadaní vlastného šťastia v živote. Ak sa všetko vo vašom živote rozpadá, potrebujete len jednu dobrú vec, ktorá vám pomôže ho dať znovu dokopy.&nbsp;&nbsp;<a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=70908&amp;pageId=resultform&amp;full=0&amp;focusName=bsktchRZ17\" target=\"_blank\">&gt;&gt;&gt;</a><br>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":193}} src={"https://cdn.swbpg.com/o/25013/226ca38094974734822bbd6941b99d08.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={""}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"Časonauti "}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"Anton Stiffel"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"Časonauti, tajomní a neohrození cestovatelia v čase. Existujú mimo bežného času a priestoru, čím tak trochu popierajú jeho základné pravidlá. Ich poslanie je však dôležité. Strážia nemennosť základného časového prúdu a konzistentnosť časovej línie. Krehká kauzalita časových väzieb sa môže narušiť veľmi ľahko, a to by mohlo mať na ďalekú budúcnosť nedozerné následky. <br>V našej minulosti sa nachádza pár pozoruhodných, neraz nevyjasnených záhad a historických udalostí, ktorým sa časonauti rozhodli pozrieť na zúbok. Pri riskantných cestách časom skúmajú, či neboli nejako ovplyvnené a či nebola narušená autenticita pôvodného časového prúdu. To nemusí byť jednoduché, pretože sa neraz dostanú do osudovej dilemy: očistiť časový tok od umelých zásahov aj za cenu rizika, že sa drasticky zmení budúcnosť alebo do toho záhadného ladenia nezasahovať, pretože je celkom možné, že bez neho by ľudstvo dávno neexistovalo. <br>&nbsp;Lenže aj časonauti sú len ľudia a môžu sa mýliť… <a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=70269&amp;showAnot=1&amp;pageId=resultform\" target=\"_blank\">&gt;&gt;&gt;</a><br>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":162}} src={"https://cdn.swbpg.com/o/25013/94112399329940e2b40521988dc173f8.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={""}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"Ilegál"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"Peter Kijaba"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"Dramatický príbeh plný napätia a prekvapujúcich zvratov sleduje osudy elitného vojaka s drsnými skúsenosťami služby v cudzineckej légii a veľkopodnikateľa, bývalého dôstojníka ŠtB pôsobiaceho v zahraničí na konci studenej vojny. Bývalý vojak Viktor po rokoch strávených v Afganistane, Mali a v ďalších krajinách zmietaných nepokojmi sa vracia do rodných Košíc, aby zmizol z hľadáčika prenasledovateľov, no ani mesto na východe Slovenska mu neprinesie vytúžené bezpečie. Predtým než zavesí nebezpečné žoldnierske remeslo na klinec, jeho cesty sa skrížia s veľkopodnikateľom Kogelom, ktorý po revolúcii v r. 1989 rýchlo naskočil na vlak smerujúci do kapitalizmu, bohato zúročiac zahraničné konexie získané v službách Štátnej bezpečnosti. Obom ide o život. Kto z nich prežije?\n <a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=70281&amp;showAnot=1&amp;pageId=resultform\" target=\"_blank\">&gt;&gt;&gt;</a><br>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":162}} src={"https://cdn.swbpg.com/o/25013/85bbf2f807434c668906911bf77627a2.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={""}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"Švédské umění dobrého stárnutí"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"Margareta Magnussonová"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"Múdre, vtipné a praktické Švédské umění dobrého starnutí je citlivou, jemnou a vítanou pripomienkou toho, že bez ohľadu na náš vek sú pred nami vždy nové, neočakávané a fascinujúce objavy a zážitky, ktorými môžeme podľa ľubovôle zaplniť každý deň.\nKniha nám ukazuje, ako sa pripraviť na proces starnutia a pomáha nám pochopiť radosti a strasti, ktoré môže toto obdobie priniesť. Hlavným posolstvom je myšlienka, že by sme sa mali menej obávať starnutia a predstavy smrti.\nTitul voľne nadväzuje na knihu Životní úklid. Nezaťažení fyzickým ani emocionálnym bremenom tak spolu s ňou môžete v ľahšie začať prežívať zmysluplne každý ďalší deň a užiť si ho. Autorka predkladá čitateľom svoje objavy a rady o starnutí – niektoré ťažšie akceptovateľné, ale mnohé až nečakane úžasné.\n <a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=70835&amp;pageId=resultform&amp;full=0&amp;focusName=bsktchRZ36\" target=\"_blank\">&gt;&gt;&gt;</a><br>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":162}} src={"https://cdn.swbpg.com/o/25013/5ced68cca40343dfa96e57986bb90be2.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={""}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"Zradca"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"Jørn Lier Horst"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"Do východu slnka nasledujúceho dňa sú všetci obyvatelia zapísaní a je jasné, že zosuv pôdy si nevyžiadal žiadne obete. A predsa sa na druhý deň medzi troskami nájde mŕtve telo. Koroner určí čas úmrtia na štyridsaťosem hodín pred zosuvom. Ukáže sa, že vyšetrovanie mužovej smrti súvisí s niekoľkými ďalšími prebiehajúcimi prípadmi a Wistinga najímajú ako vedúceho samostatnej jednotky. Čoskoro sa však začnú objavovať náznaky, že medzi vyšetrovateľmi je zradca...\n <a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=70428&amp;showAnot=1&amp;pageId=resultform\" target=\"_blank\">&gt;&gt;&gt;</a><br>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":162}} src={"https://cdn.swbpg.com/o/25013/03aa3b5e0f0e4bb082518aa4f59762c0.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={""}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"Veľká kniha slovenských povestí. 2. diel"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"Zuzana Kuglerová &nbsp;"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"Zuzana Kuglerová sa už 20 rokov venuje zbieraniu povestí. V jej najnovšej knihe nájdete príbehy z viacerých regiónov Slovenska. Kým v prvom diele spájala jednotlivé povesti časová línia, v druhom diele je to línia „miesta“, resp. regiónu. Autorka vás prevedie Budatínskym, ale aj Budmerickým zámkom, spolu s ňou navštívite mocných zemepánov aj chudobné siroty. Spoznáte svet zbojníkov aj krutú grófku, ktorá nedovolila svojmu synovi, aby sa oženil s chudobnou pastierkou pávov. Dozviete sa tajomstvá, čo mali navždy zostať skryté za múrmi hradov.\n <a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=70568&amp;pageId=resultform&amp;full=0&amp;focusName=bsktchRZ13\" target=\"_blank\">&gt;&gt;&gt;</a><br>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":162}} src={"https://cdn.swbpg.com/o/25013/3dd06c53c2fc4e7caf263d531583aee5.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={""}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"Mafiánske balady"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"Dušan Taragel "}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"Všetci zomrieme, nikto však nevie kedy, kde a ako. Nevedia to ani hlavné postavy akčného románu Mafiánske balady. Osudovo ich spojí mŕtvola zahrabaná v lese a jej nález rozbehne reťazec udalostí, ktoré už nikto z nich nedokáže zastaviť. Sedem príbehov o posledných dňoch a hodinách života ľudí, ktorí uverili, že ich práve postretlo šťastie. <a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=70272&amp;showAnot=1&amp;pageId=resultform\" target=\"_blank\">&gt;&gt;&gt;</a><br>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":162}} src={"https://cdn.swbpg.com/o/25013/f63c63df086c448487b50f83eb2f5db8.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={""}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"Dych"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"James Nestor "}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"Ako sa stravujete, koľko cvičíte, akí štíhli, mladí alebo silní ste - na ničom z toho nezáleží, ak nedýchate správne. Pre naše zdravie a celkovú pohodu nie je nič dôležitejšie než dýchanie – nádych, výdych, zopakovať dvadsaťpäťtisíckrát denne. Ľudia však prišli o schopnosť správne dýchať a má to vážne následky. Novinár James Nestor cestuje po svete a pátra po tom, kde sa stala chyba a ako to napraviť. Vyhľadáva ľudí skúmajúcich vedu ukrytú za starovekými dýchacími technikami, ako pránájáma, sudaršana-krijá či tummo, a spolupracuje s nadšencami dýchania, ktorých nazýva pneumonauti, aby vedecky preveril dávno existujúce teórie dýchania. . &nbsp;<a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=70289&amp;showAnot=1&amp;pageId=resultform\" target=\"_blank\">&gt;&gt;&gt;</a><br>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":162}} src={"https://cdn.swbpg.com/o/25013/6314b62f492f430ba892cba2728dd5d1.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={""}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"V neistej bitke"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"John Steinbeck"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"Raný román držiteľa Nobelovej ceny za literatúru Johna Steinbecka. V neistej bitke stojí na začiatku jeho rozsiahleho spoločensky kritického diela. Zaoberá sa životom chudobných vrstiev v Kalifornii a ich bojom so sociálnou nespravodlivosťou. Rozpráva príbeh zberačov jabĺk, ktorí sa snažia vydobyť si štrajkom lepšie pracovné podmienky. &nbsp;<a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=70274&amp;showAnot=1&amp;pageId=resultform\" target=\"_blank\">&gt;&gt;&gt;</a><br>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":162}} src={"https://cdn.swbpg.com/o/25013/34ae778a09c5453795440a48d222ea8b.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={""}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"Zbojníci. Povesti a príbehy o horehronských tovarišoch smelého remesla"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"František Kreutz,  Peter Mišák"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"V rušnom osemnástom storočí sužovali celé Slovensko vzbury, povstania, vojny i rozličné choroby. Útlak a bieda boli každodenným údelom. Mnohí sa s tým odmietli zmieriť a brali spravodlivosť i svoj osud do vlastných rúk. Skoro všetky príbehy, ktoré vám vyrozpráva táto kniha, sa naozaj stali. O najznámejšom horehronskom zbojníkovi Jakubovi Surovcovi nezachovala ľudová pamäť toľko piesní a legiend ako o terchovskom Jurovi Jánošíkovi. Čriepky príbehov zozbieral a pospájal historik a spisovateľ František Kreutz z ľudovej tradície i z mestských kroník a ďalších zachovaných prameňov. Knihu o ťažkom živote Horehroncov a o smelých skutkoch ich ochrancov a naprávačov panských krívd ponúkame ako ďalší dielik do mozaiky povesťovej mapy Slovenska.&nbsp;<a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=70268&amp;pageId=resultform&amp;full=0&amp;focusName=bsktchRZ87\" target=\"_blank\">&gt;&gt;&gt;</a><br>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":162}} src={"https://cdn.swbpg.com/o/25013/70de56411bff441d8553f6e044095088.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={""}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"Krvavé slzy posvätnej hory"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"Tatiana Macková"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"Anahit, dcéra arménskeho imigranta, žije spokojný život po boku milovaného muža. Až do jedného nedeľného rána, keď sa s manželom rozhodnú navštíviť jej starnúceho otca a presvedčiť ho, aby sa k nim presťahoval. Od chvíle, kedy dorazia k jeho domu, všetko, čo poznali, kamsi zmizlo. Otcova náhla smrť otvorila dvere do minulosti. A s ňou prišli otázky. Kým v skutočnosti bol? Prečo ide Anahit odrazu o život? Čo všetko odhalia dlho ukrývané listiny? Mladá žena postupne nachádza odpovede. Zisťuje, čo sa kedysi odohralo na území Osmanskej ríše. Aj to, prečo je navždy spojená s touto desivou históriou.&nbsp;<a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=70149&amp;showAnot=1&amp;pageId=resultform\" target=\"_blank\">&gt;&gt;&gt;</a><br>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":162}} src={"https://cdn.swbpg.com/o/25013/32c9400e6cfc4885a888d5ff84d9647d.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={""}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"Základy"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"Euklides"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"Euklides v Základoch zhrnul, systematicky usporiadal, doplnil, rozvinul a didakticky majstrovským spôsobom prezentoval všetky podstatné vedecké výsledky v matematike, ku ktorým grécka veda dospela v 6. – 4. storočí p. n. l. V trinástich kapitolách sú v obsahovej a logickej gradácii zachytené temer všetky originálne prínosy antickej gréckej geometrie, aritmetiky a algebry.&nbsp;<a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=70288&amp;pageId=resultform&amp;full=0&amp;focusName=bsktchRZ39\" target=\"_blank\">&gt;&gt;&gt;</a><br>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":162}} src={"https://cdn.swbpg.com/o/25013/6caeec5168724630b15013b0db1a65ad.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={""}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"Sudcov zoznam"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"John Grisham"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"Lacy Stoltzová, známa z románu Svišť, stretne záhadnú, vystrašenú ženu. Jej otca zavraždili pred dvadsiatimi rokmi, policajtom sa nepodarilo prípad vyriešiť. Lenže žena má podozrivého a sleduje ho už dvadsať rokov. Popritom odhalila ďalšie obete. Podozrenie však nestačí, no nájsť dôkazy sa zdá nemožné. Ide o mimoriadne rafinovaného sériového vraha. Vyzná sa vo forenzných vedách, ovláda policajné postupy a čo je najdôležitejšie: pozná zákony. Ako môže Lacy začať vyšetrovanie a nedostať sa do jeho zoznamu? Sudcov zoznam je bezpochyby dosiaľ najmrazivejší román Johna Grishama.<a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=70189&amp;showAnot=1&amp;pageId=resultform\" target=\"_blank\">&gt;&gt;&gt;</a><br>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":162}} src={"https://cdn.swbpg.com/t/25013/cc0b2c2782ab4646a7419371283421c1_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/25013/cc0b2c2782ab4646a7419371283421c1_s=350x_.jpg 350w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"Zakliata jaskyňa"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"Mariana Čengel Solčanská"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"Dokáže láska prelomiť dávnu kliatbu?\nPôvodná slovenská rozprávka z pera uznávanej režisérky, scenáristky a autorky Mariany Č. Solčanskej vás prenesie do kráľovstva, v ktorom rozum a rozvahu nahradí chamtivosť a zlo. Podarí sa láske ustáť všetky prekážky a zvíťaziť nad kliatbou? <a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=70127&amp;showAnot=1&amp;pageId=resultform\" target=\"_blank\">&gt;&gt;&gt;</a><br>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":162}} src={"https://cdn.swbpg.com/o/25013/4016399185c94ca0b2af33cc87a0575c.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={""}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"Aféra pána Christieho"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"Nina de Gramont"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"Nan O'Deaová, mladá atraktívna Írka, prenikne do fascinujúceho sveta známej spisovateľky Agathy Christie a rýchlo si získa priazeň jej manžela plukovníka Archieho Christieho. Deň po tom, čo Archie oznámi svojej žene, že sa chce dať rozviesť, aby sa mohol oženiť s Nan, Agatha Christie zmizne bez stopy. Nikto však netuší, kto je Nan v skutočnosti. Aké tajomstvo ukrýva? Čoho všetkého je schopná v mene lásky? Aký zločin nedokáže nikdy odpustiť? Strhujúci román vychádza zo skutočnej udalosti – dodnes neobjasneného zmiznutia slávnej autorky detektívnych románov Agathy Christie v decembri 1926.  <a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=70193&amp;showAnot=1&amp;pageId=resultform\" target=\"_blank\">&gt;&gt;&gt;</a><br>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":162}} src={"https://cdn.swbpg.com/o/25013/24151dbbd7254b19952acdc2521942ec.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={""}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"A vy sa ako máte?"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"Peter Bielik"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"Kniha A VY SA AKO MÁTE? nesie podnázov Skutočné príbehy z praxe mentálneho kouča, ktorými sa autor chce čitateľom priblížiť a ukázať im svoj vlastný pohľad pri riešení rôznych životných situácií. Peter Bielik zdieľa s čitateľom svoju skúsenosť na pozadí príbehov, ktoré sa ho dotkli odborne aj ľudsky. Spísaním svojich poznatkov a skúseností chce aj touto formou pomáhať ešte väčšiemu počtu ľudí, než to dokáže robiť osobne.  <a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=70069&amp;pageId=resultform&amp;full=0&amp;focusName=bsktchRZ24\" target=\"_blank\">&gt;&gt;&gt;</a><br>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":162}} src={"https://cdn.swbpg.com/o/25013/2da528468b254a19a068f184b9ac0cb0.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={""}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"Všade samé zázraky"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"Jana Benková"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"Ak ste sa zamilovali do muža, ktorý nedokáže ukončiť nadštandardný vzťah s bývalou partnerkou či dokonca manželkou, tak pozor a utekajte od neho čo najďalej! Stále s ňou telefonuje? Vyhovára sa, že ho jeho ex potrebuje? A že si sama neporadí s nákupmi? Že si s ňou večer vyjde von, veď je to „len“ kino či divadlo? Dámy, od takého chlapa ruky preč!  <a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=69909&amp;pageId=resultform&amp;full=0&amp;focusName=bsktchRZ48\" target=\"_blank\">&gt;&gt;&gt;</a><br>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":162}} src={"https://cdn.swbpg.com/o/25013/793b6a443c7443b9bdba83906d27b49b.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={""}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"Modré srdce s kečupovým fľakom"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"Marta Hlušíková"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"Deväťročná Laura má najväčšiu nohu v triede, geniálneho malého brata, v triede dve namyslené nepriateľky a jednu super kamošku. Okrem toho Laura ako jediná dostane od Lukiho modré srdce. Každý iný chlapec by jej daroval srdce červené, a nie modré zašpinené od kečupu. Napriek tomu všetkému je Laura dievča ako každé iné - raz sa trápi a raz smeje. To je asi u zaľúbených dievčat normálne.  <a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=69802&amp;pageId=resultform&amp;full=0&amp;focusName=bsktchRZ80\" target=\"_blank\">&gt;&gt;&gt;</a><br>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":162}} src={"https://cdn.swbpg.com/o/25013/b07ddffd80b14d2184e416ff95af32ec.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={""}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"Polnočná ruža"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"Lucinda Rileyová"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"Strhujúci príbeh indickej dievčiny Anáhity a jej rodiny sa odohráva v rozpätí štyroch generácií na dvoch kontinentoch – vo veľkolepom paláci indického maharadžu a v majestátnom šľachtickom sídle na juhu Anglicka. V čase najväčšieho rozmachu britského koloniálneho panstva v Indii nadviaže jedenásťročná Anáhitá zo vznešenej, ale chudobnej rodiny celoživotné priateľstvo s tvrdohlavou princeznou Indirou, privilegovanou dcérou indického maharadžu.&nbsp;&nbsp;<a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=69945&amp;pageId=resultform&amp;full=0&amp;focusName=bsktchRZ72\" target=\"_blank\">&gt;&gt;&gt;</a><br>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":162}} src={"https://cdn.swbpg.com/t/25013/88285291a38544be9a92fa9d51d6ab25_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/25013/88285291a38544be9a92fa9d51d6ab25_s=350x_.jpg 350w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"Detský mozog: Novorodenec"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"Robert Krause"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"Detský mozog sa začína rozvíjať počas vnútromaternicového vývinu a jeho dozrievanie rapídnym spôsobom akceleruje v prvých mesiacoch a rokoch života. Neuropsychológ Robert Krause vás prevedie dvadsiatimi štyrmi modelovými situáciami, ktoré vám pomôžu pochopiť vývin vášho dieťaťa. Na týchto názorných príkladoch vám ukáže a pomocou najnovších vedeckých poznatkov vysvetlí, čo robiť a čomu sa pre zmenu vyhýbať, aby ste podporili zdravé dozrievanie mozgu dieťaťa.    <a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=70198&amp;pageId=resultform&amp;full=0&amp;focusName=bsktchRZ1\" target=\"_blank\">&gt;&gt;&gt;</a><br>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":162}} src={"https://cdn.swbpg.com/o/25013/a3755438339f4795b0b70d80dab133d8.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={""}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"Dáma kontra strelec"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"Dominik Dán"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"V polovici deväťdesiatych rokov sa v Našom Meste aktivizovalo podsvetie a chlapci z oddelenia vrážd mali plné ruky práce. No nie všetky vraždy z tohto obdobia súviseli s podsvetím. Našli sa aj také, ktoré na svoje vyriešenie čakali v Krauzovej skrini roky, a nakoniec sa dočkali. Po troch rokoch vyšetrovania sa detektívom podarilo chytiť vraha sedemnásťročnej prostitútky Ruženky Róžovej, dopichanej v tráve na kraji sídliska.    <a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=69775&amp;pageId=resultform&amp;full=0&amp;focusName=bsktchRZ99\" target=\"_blank\">&gt;&gt;&gt;</a><br>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":162}} src={"https://cdn.swbpg.com/t/25013/ea402a8ad02e42ecb30845bcf4ca7774_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/25013/ea402a8ad02e42ecb30845bcf4ca7774_s=350x_.jpg 350w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"Zrnko"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"Branislav Jobus"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"Zrnko je príbeh o malom zrnku peľu, ktoré si poletuje svetom a snaží sa nájsť miesto, kam patrí. Podarí sa mu to? Čaká ho veru neľahká cesta. Počas svojho putovania zažije mnoho dobrodružstiev aj nebezpečenstiev a zoznámi sa s viacerými environmentálnymi problémami.   <a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=69441&amp;pageId=resultform&amp;full=0&amp;focusName=bsktchRZ39\" target=\"_blank\">&gt;&gt;&gt;</a><br>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":162}} src={"https://cdn.swbpg.com/o/25013/f84f24faa4c34707a4f9706cd39794b3.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={""}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"Predpoveď"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"Darren Sugrue"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"Daniel Geller je matematický génius, ktorému sa podarilo nemožné – objavil vzorec na výpočet dátumu ľudskej smrti. Vedecká komunita Dublinskej univerzity má však iný názor. Odmieta Danielov objav, a tak sa zatrpknutý matematik rozhodne všetko nechať.\nO dvanásť rokov sa jeden z Danielových bývalých profesorov John Redmond a jeho manželka vyrovnávajú so smrťou desaťročného syna. Mohol Danielov vzorec predpovedať jeho smrť?  <a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=68904&amp;pageId=resultform&amp;full=0&amp;focusName=bsktchRZ10\" target=\"_blank\">&gt;&gt;&gt;</a><br>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":162}} src={"https://cdn.swbpg.com/o/25013/68e6efed5f61445c85677320c9efbe56.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={""}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"Kde sa píšu čiarky?  "}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"Mária Beláková"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"Je pre vás ťažké určiť, kam čiarka patrí a kam zase nie? Precvičte si písanie čiarok pomocou jednoduchých cvičení a hravou formou sa naučíte presné pravidlá ich písania. <a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=69435&amp;pageId=resultform&amp;full=0&amp;focusName=bsktchRZ1\" target=\"_blank\">&gt;&gt;&gt;</a><br>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":162}} src={"https://cdn.swbpg.com/o/25013/b49eed5c5ece43be9368cbdebe2f68d3.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={""}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"Jozef Mak / Žltý dom v Klokoči "}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"Jozef Cíger Hronský"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"Výber vrcholných diel Jozefa Cígera Hronského v jednej publikácii. Román Jozef Mak patrí k najvýznamnejším dielam slovenskej medzivojnovej literatúry. Románový debut z roku 1927, Žltý dom v Klokoči, je príbeh chorobnej lásky k peniazom a jej deštruktívnej sile. <a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=69429&amp;pageId=resultform&amp;full=0&amp;focusName=bsktchRZ1\" target=\"_blank\">&gt;&gt;&gt;</a><br>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":162}} src={"https://cdn.swbpg.com/o/25013/07cc6160ea734f1ebd4bc897a6693997.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={""}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"Einstein a stroje času"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"Luca Novelli"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"Kniha Einstein a stroje času je o geniálnych myšlienkach a teóriách, ktoré sa preháňajú v hlave najväčšieho vedca 20. storočia. Sám Albert Einstein v nej rozpráva o teórii relativity, a aj o tom, čo v živote navyvádzal. Hovorí o svojej láske k fyzike a husliam, o tom, ako musel ujsť pred nacizmom do Ameriky, a aj o tom, prečo je lepší mier ako vojna. <a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=69433&amp;pageId=resultform&amp;full=0&amp;focusName=bsktchRZ1\" target=\"_blank\">&gt;&gt;&gt;</a><br>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":162}} src={"https://cdn.swbpg.com/o/25013/750afe14c88641fab6745deadd9006d1.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={""}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"Volám sa Joy"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"Mariapia Bonanateová"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"Píše sa rok 2016. Mladá nigérijská žena Joy sníva svoj sen o krásnej budúcnosti. Realita, v ktorej žije, jej ho však nedovolí dosnívať. Na jednej strane vidí chudobu krajiny a biedu rodiny, na druhej strane jej zhoda udalostí dáva nádej na dobrý zárobok v Európe. Odísť alebo neodísť? Sen je silnejší. Rozhodne sa. A tu sa začína skutočný a dramatický príbeh, ktorý vás priam vtláča do kresla...&nbsp;<a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=69421&amp;showAnot=1&amp;pageId=resultform\" target=\"_blank\">&gt;&gt;&gt;</a><br>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":162}} src={"https://cdn.swbpg.com/t/25013/e1803f5be5234ba6a70de2e203e98d01_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/25013/e1803f5be5234ba6a70de2e203e98d01_s=350x_.jpg 350w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"Dievča, ktoré chcelo zachrániť knihy"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"Klaus Hagerup a Lisa Aisato"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"Poetický príbeh o nekonečnej láske ku knihám a o mágii, ktorá sa v nich skrýva\n\nViete, čo sa deje s knihami, ktoré si z knižnice nikto nepožičiava? Nevedela to ani desaťročná vášnivá čitateľka Anna, kým jej to neprezradila najlepšia kamarátka, knihovníčka pani Monsenová. <a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=69213&amp;pageId=resultform&amp;full=0&amp;focusName=bsktchRZ1\" target=\"_blank\">&gt;&gt;&gt;</a><br>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":162}} src={"https://cdn.swbpg.com/t/25013/272192762ffb4c818f00e316c9575baf_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/25013/272192762ffb4c818f00e316c9575baf_s=350x_.jpg 350w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"Cez zrkadlo"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"Zdenka Laciková"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"Na svet sa dá nazerať rôzne. Realisticky, optimisticky, čiernobielo, s pochopením, zatrpknuto.... Básne regionálnej poetky Zdenky Lacikovej s typickou žensky krehkou lyrikou sú písané viazaným veršom. Kniha prináša čitateľovi aj nápadité farebné portrétové fotografie fotografa Stana Fehéra. <a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=69140&amp;showAnot=1&amp;pageId=resultform\" target=\"_blank\">&gt;&gt;&gt;</a><br>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":162}} src={"https://cdn.swbpg.com/o/25013/1693dfb4f67b4c31abe66553bf695469.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={""}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"Atlas zabudnutých miest "}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"Travis Elborough"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"Vydajte sa na cestu po opustených miestach sveta. K tajomným spiacim monumentom roztrúseným po našej planéte, ktoré boli odsunuté na okraj dejín, vytratili sa z máp aj z nášho zorného poľa. Travis Elborough zvečnil osudy týchto zvláštnych, prehliadaných a miznúcich svetov – od starovekých ruín a rozpadávajúcich sa zámkov po oveľa mladšie pozostatky niekdajšej slávy ako newyorská stanica metra v štýle art deco, sovietske mesto duchov za polárnym kruhom či zatopené obchodné centrum v Thajsku plné rýb. <a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=69305&amp;pageId=resultform&amp;full=0&amp;focusName=bsktchRZ1\" target=\"_blank\">&gt;&gt;&gt;</a>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":162}} src={"https://cdn.swbpg.com/o/25013/7e23f6d2ac204ffbb76d021c695ad7cc.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={""}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"Výnimočná"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"Katarína Gillerová"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"Nesmierny obdiv, ktorý pätnásťročná modelka Diana pociťuje k fotografovi Alanovi, jej pomáha prekonať bolesť spôsobenú otcovým odchodom od rodiny. Ich vzťah neskôr prerastie do vášnivej lásky. Napriek tomu, že Alan sa nechce viazať, Diana dosiahne, že začnú spolu žiť a narodí sa im dcérka. <a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=69299&amp;showAnot=1&amp;pageId=resultform\" target=\"_blank\">&gt;&gt;&gt;</a>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":162}} src={"https://cdn.swbpg.com/o/25013/91dcd0528e5b4dc2bec5e56b95eee943.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={""}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"Misia detektívi : mozgohry"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"Rafa Guerrero, Carolina Laguna\n"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"Prostredníctvom interaktívneho príbehu poskytuje táto kniha ideálny tréning pre rozvoj rôznych neuropsychologických, kognitívnych a emocionálnych zručností. Čitateľ má chuť čítať vďaka jasnému, jednoduchému a zábavnému textu.  Pri čítaní knihy musia deti dávať veľký pozor, pretože je plná výziev a úloh, ktoré budú musieť postupne vyriešiť, aby pomohli hlavným hrdinom úspešne splniť ich misiu. <a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=69330&amp;showAnot=1&amp;pageId=resultform\" target=\"_blank\">&gt;&gt;&gt;</a>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--10 pt--10" name={"ntcvk73w0u"} layout={"l3"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":162}} src={"https://cdn.swbpg.com/o/25013/b1176db21c074f9bae2a0e3709f63867.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={""}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"Frida"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"Maren Gottschalk"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"Frida Kahlo je jednou z najslávnejších maliarok na svete, jej dielo patrí do kultúrneho bohatstva Mexika. Pútavý, emóciami nabitý román zachytáva legendárnu umelkyňu v rozhodujúcom období života - na prahu celosvetovej slávy. <a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=68717&amp;pageId=resultform&amp;full=0&amp;focusName=bsktchRZ7\" target=\"_blank\">&gt;&gt;&gt;</a>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--10 pt--10" name={"ntcvk73w0u"} layout={"l3"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":162}} src={"https://cdn.swbpg.com/o/25013/55ceadecfb5c4fad90e6f4b9bff58414.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={""}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"Stratené umenie myslieť"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"Neil Nedley; Miroslava Obuchová"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"Ako zlepšiť emocionálnu inteligenciu a dosiahnuť vysokú duševnú výkonnosť. Ako životný štýl ovplyvňuje náš mozog. Tretia časť. Ako všetko spojiť. <a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=68717&amp;pageId=resultform&amp;full=0&amp;focusName=bsktchRZ7\" target=\"_blank\">&gt;&gt;&gt;</a>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--10 pt--10" name={"ntcvk73w0u"} layout={"l3"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":162}} src={"https://cdn.swbpg.com/t/25013/14c0320355044274bb2ec641eca84f29_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={""}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"Štefan Boleslav Roman - Uránový kráľ"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"Zostavil: Joseph M. Burza"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"Štefan Boleslav Roman bol najvýznamnejší podnikateľ slovenského pôvodu v histórii. Svojim obrovským významom a dielom je prirovnávaný k Milanovi Rastislavovi Štefánikovi. <a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=68723&amp;pageId=resultform&amp;full=0&amp;focusName=bsktchRZ1\" target=\"_blank\">&gt;&gt;&gt;</a>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--10 pt--10" name={"ntcvk73w0u"} layout={"l3"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":162}} src={"https://cdn.swbpg.com/t/25013/5554fa991de24277b2b5d06f8679d93f_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={""}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"Zoznam prianí"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"Viktória Dominová"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"Chvejúce sa kolená, mravčenie v žalúdku, zrumenené líca, váhavé pohľady... To je nič v porovnaní s tým, čo cíti Sára v Adamovej blízkosti. Zdôveriť sa môže len svojmu strelenému kamošovi a babičke, v ktorých nachádza oporu. <a href=\"https://topolcany.dawinci.sk/?fn=*recview&amp;uid=68662&amp;pageId=resultform&amp;full=0&amp;focusName=bsktchRZ23\" target=\"_blank\">&gt;&gt;&gt;</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"sluzby-2"} layout={"l30"}>
        </Column>


        <Column className="--center pb--20 pt--20" style={{"backgroundColor":"var(--color-supplementary)"}} name={"paticka"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--left fs--20" content={"<span style=\"color: var(--color-dominant)\">Tribečská knižnica v Topoľčanoch</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--right fs--20" content={"<span style=\"color: var(--color-dominant)\">2023&nbsp;</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}